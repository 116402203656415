import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import Newsletter from "components/Newsletter"

import { Content } from "page_components/blog"

const Blog = ({ data }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout isHeaderTransparent>
      <Breadcrumbs title="Blog" isDark />
      <PageHeader
        title={pageData?.acfBlog?.header}
        content={pageData?.acfBlog?.subHeader}
        isDark
        marginZero
      />
      <Content
        data={data?.allWpPost?.nodes}
        categories={data?.allWpCategory?.nodes}
        pageData={pageData}
      />
      <Newsletter />
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPage?.nodes[0]?.seo?.title
        ? data?.allWpPage?.nodes[0]?.seo?.title
        : data?.allWpPage?.nodes[0]?.title
    }
    description={
      data?.allWpPage?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPage?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpCategory {
      nodes {
        name
        slug
      }
    }
    allWpPost {
      nodes {
        id
        title
        uri
        slug
        excerpt
        date
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    allWpPage(filter: { id: { eq: "cG9zdDo3MzY0" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
        acfBlog {
          header
          subHeader
          description
        }
      }
    }
  }
`

export default Blog
